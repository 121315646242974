<template>
  <div>
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div v-if="item">
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-card>
                <b-tabs>
                  <b-tab
                    v-for="language in languages"
                    :key="language.code"
                  >
                    <template #title>
                      <b-img
                        :src="require('@/assets/images/flags/' + language.code + '.svg')"
                        height="16"
                        width="16"
                        class="mr-1"
                      />
                      <span class="d-none d-sm-inline">{{ language.title }}</span>
                    </template>
                    <b-row>
                      <b-col
                        cols="24"
                        md="12"
                      >
                        <b-form-group
                          :label="$t('form.title.label')"
                          :label-for="'title.' + language.code"
                        >
                          <b-form-input
                            :id="'title.' + language.code"
                            v-model="item.title[language.code]"
                            :state="errors && errors['title.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                            {{ errors['title.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="24"
                        md="12"
                      >
                        <b-form-group
                          :label="$t('form.address.label')"
                          :label-for="'address.' + language.code"
                        >
                          <b-form-input
                            :id="'address.' + language.code"
                            v-model="item.address[language.code]"
                            :state="errors && errors['address.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['address.' + language.code]">
                            {{ errors['address.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="24"
                        md="12"
                      >
                        <b-form-group
                          :label="$t('form.schedule.label')"
                          :label-for="'schedule.' + language.code"
                        >
                          <b-form-input
                            :id="'schedule.' + language.code"
                            v-model="item.schedule[language.code]"
                            :state="errors && errors['schedule.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['schedule.' + language.code]">
                            {{ errors['schedule.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-tab>
                </b-tabs>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-card>
                <b-row>
                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      :label="$t('form.href.label')"
                      label-for="href"
                    >
                      <b-form-input
                        id="slug"
                        v-model="item.href"
                        :state="errors && errors.href ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors.href">
                        {{ errors.href[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('form.phone.label')"
                      label-for="phone"
                    >
                      <b-form-input
                        id="phone"
                        v-model="item.phone"
                        :state="errors && errors.phone ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors.phone">
                        {{ errors.phone[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('form.latitude.label')"
                      label-for="latitude"
                    >
                      <b-form-input
                        id="latitude"
                        v-model="item.latitude"
                        :state="errors && errors.latitude ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors.latitude">
                        {{ errors.latitude[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('form.longitude.label')"
                      label-for="longitude"
                    >
                      <b-form-input
                        id="longitude"
                        v-model="item.longitude"
                        :state="errors && errors.longitude ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors.longitude">
                        {{ errors.longitude[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('form.position.label')"
                      label-for="position"
                    >
                      <b-form-input
                        id="position"
                        v-model="item.position"
                        type="number"
                        step="1"
                        :state="errors && errors.position ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors.position">
                        {{ errors.position[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('form.can_pickup.label')"
                      label-for="can_pickup"
                    >
                      <b-form-checkbox
                        id="can_pickup"
                        v-model="item.can_pickup"
                        value="1"
                        :state="errors && errors.can_pickup ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors.can_pickup">
                        {{ errors.can_pickup[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col cols="12">
              <b-card>
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="onSubmit"
                >
                  {{ $t('general.save') }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                  :to="{ name: 'shops-index' }"
                >
                  {{ $t('general.cancel') }}
                </b-button>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormInvalidFeedback,
  BImg,
  BOverlay, BFormCheckbox,
} from 'bootstrap-vue'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormCheckbox,
    BTab,
    BTabs,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BImg,
    BFormInvalidFeedback,
    BOverlay,
  },
  data() {
    return {
      item: null,
      languages: [],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    this.item = await this.transformData()
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async onSubmit() {
      const data = {
        title: this.item.title,
        address: this.item.address,
        schedule: this.item.schedule,
        href: this.item.href,
        phone: this.item.phone,
        latitude: this.item.latitude,
        longitude: this.item.longitude,
        can_pickup: this.item.can_pickup,
        position: this.item.position,
      }

      this.$http.post('/api/administration/shops', data)
        .then(() => {
          router.replace({ name: 'shops-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    transformData() {
      const data = {
        title: {},
        address: {},
        schedule: {},
        href: null,
        phone: null,
        latitude: null,
        longitude: null,
        can_pickup: 1,
        position: 1,
      }

      _.each(this.languages, language => {
        data.title[language.code] = null
        data.address[language.code] = null
        data.schedule[language.code] = null
      })

      return data
    },
  },
}
</script>
